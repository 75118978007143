<template>
  <div>
    <div class="fill-height background-style">
      <Navbar />
      <v-container class="px-5 px-sm-9 pb-0">
        <SearchBar />
      </v-container>
      <v-container class="px-0 px-md-2 pt-0 pb-5">
        <div
          class="white--text"
          :style="[
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? { padding: '50px 40px' }
              : { padding: '70px 30px 20px 30px' },
          ]"
        >
          <p class="text-h1 font-weight-bold">Políticas de privacidad</p>
        </div>
      </v-container>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-top': '30px', 'padding-bottom': '10px' }
          : { 'padding-top': '50px', 'padding-bottom': '10px' },
      ]"
      class="body-container mb-16"
    >
      <v-container class="px-10 px-md-auto pb-md-0 mb-16 text-h3">
        <div>
          <p class="text-h2 font-weight-bold secondary--text">
            Términos y condiciones Zaturna
          </p>
          <br />
          <br />
          <div>
            Las presentes Condiciones de uso (“Condiciones”) regulan el acceso o
            uso que usted haga, como persona, desde cualquier país del mundo
            (excluidos los Estados Unidos y sus territorios y posesiones así
            como China Continental) de aplicaciones, páginas web, contenido,
            productos y servicios (los “Servicios”) puestos a disposición por
            Zaturna.
            <br />
            <br />
            Lea estas condiciones detenidamente antes de acceder o usar los
            servicios.
            <br />
            <br />
            Los Servicios constituyen una plataforma de tecnología que funge
            como enlace y permite a los usuarios de aplicaciones móviles de
            Zaturna o páginas web proporcionadas como parte de los Servicios
            (cada una, una “Aplicación”) organizar, rentar, comprar y/o planear
            con proveedores independientes o personas morales de dichos
            servicios que se soliciten. (Clientes y/o Proveedor).
            <br />
            <br />
            Mediante su acceso y uso de los Servicios usted acuerda vincularse
            jurídicamente por estas Condiciones, que establecen una relación
            contractual entre usted y Zaturna. Si usted no acepta estas
            Condiciones, no podrá acceder o usar los Servicios. Estas
            Condiciones sustituyen expresamente los acuerdos o compromisos
            previos con usted. En caso de un incumplimiento a los presentes
            Términos y Condiciones y/o a las Guías Comunitarias por parte de
            usted, Zaturna podrá denegarle el acceso a su cuenta y/o a los
            Servicios. Por otro lado, Zaturna podrá modificar o poner fin a
            estos Términos y Condiciones o a cualquiera de los Servicios
            ofrecidos en las aplicaciones de manera total o parcial, mediante
            notificación previa a través de las aplicaciones y/o correo
            electrónico.
            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">Cuentas de usuario</div>
            <br />
            Con el fin de usar la mayor parte de los aspectos de los Servicios,
            usted debe registrarse y mantener activa una cuenta personal de
            usuario de los Servicios (“Cuenta”). Para obtener una Cuenta debe
            tener como mínimo 18 años, o tener la mayoría de edad legal en su
            jurisdicción (si es distinta a los 18 años). El registro de la
            cuenta le requiere que comunique a Zaturna determinada información
            personal, como su nombre, dirección, número de teléfono móvil, así
            como por lo menos un método de pago válido (bien una tarjeta de
            crédito o bien un socio de pago aceptado). Usted se compromete a
            mantener la información en su Cuenta de forma exacta, completa y
            actualizada. Si no mantiene la información de Cuenta de forma
            exacta, completa y actualizada, incluso el tener un método de pago
            inválido o que haya vencido, podrá resultar en su imposibilidad para
            acceder y utilizar los Servicios o en la resolución por parte de
            Zaturna de estas Condiciones celebradas con usted. Usted es
            responsable de toda la actividad que ocurre en su Cuenta y se
            compromete a mantener en todo momento de forma segura y secreta el
            nombre de usuario y la contraseña de su Cuenta.
            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">
              Contenido proporcionado por el usuario
            </div>
            <br />
            Zaturna podrá permitirle, cuando considere oportuno, que envíe,
            cargue, publique o de otro modo ponga a disposición de Zaturna, a
            través de los Servicios, contenido e información de texto, audio y/o
            visual, incluidos comentarios y opiniones relativos a los Servicios,
            iniciación de peticiones de apoyo, así como presentación de
            admisiones para competiciones y promociones (“Contenido de
            usuario”). Todo Contenido de usuario facilitado por usted y seguirá
            siendo de su propiedad.
            <br />
            <br />
            Usted declara y garantiza que: (i) es el único y exclusivo
            propietario de todo el Contenido de usuario o que tiene todos los
            derechos, licencias, consentimientos y permisos necesarios para
            otorgar a Zaturna su contenido de usuario como se establecio
            anteriormente; y (ii) ni el Contenido de usuario ni su presentación,
            carga, publicación o puesta a disposición de otro modo de dicho
            contenido de usuario, ni el uso por parte de Zaturna del contenido
            de usuario como está aquí permitido, infringirán, malversarán o
            violarán la propiedad intelectual o los derechos de propiedad de un
            tercero o los derechos de publicidad o privacidad o resultarán en la
            violación de cualquier ley o reglamento aplicable.
            <br />
            <br />
            Usted acuerda no proporcionar Contenido de usuario que sea
            difamatorio, calumnioso, odioso, violento, obsceno, pornográfico,
            ilícito o de otro modo ofensivo, como determine Zaturna, a su sola
            discreción, tanto si dicho material pueda estar protegido o no por
            la ley. Zaturna podrá, a su sola discreción y en cualquier momento y
            por cualquier motivo, sin avisarle previamente, revisar, controlar o
            eliminar Contenido de usuario, pero sin estar obligada a ello.

            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">Proveedores</div>
            <br />
            Para ser proveedor Zaturna debes crear el perfil de tu empresa el
            cual debe estar asociado a un registro federal de contribuyentes
            (RFC) y un número de cuenta que deberás dar de alta en el portal.
            Todos los servicios que se den de alta por parte de un Proveedor
            deberán presentar precios con IVA incluido. Para poder ser
            promocionado y utilizar las herramientas de la plataforma Zaturna,
            cada servicio que alquile y obtenga a través de la misma Zaturna
            cobrara el 10% del total de cada servicio. Zaturna no se hace
            responsable de los servicios solicitados; cada proveedor es
            responsable de los acuerdos pactados por el responsable.
            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">Pagos.</div>
            <br />
            Zaturna se encargara de bloquear las fechas solicitadas por los
            clientes con los proveedores anticipando 30% del total de servicios
            el cual se hará llegar al proveedor directamente menos la comisión
            correspondiente del 10%. Zaturna otorgara únicamente 20 días antes
            del evento a los clientes para liquidar los servicios contratados,
            los proveedores recibirán el pago de sus servicios de 3 a 5 días
            hábiles después de la fecha del evento. Zaturna funge como enlace
            entre cliente y/o proveedor. Si el cliente no liquida antes del
            evento se notificara al proveedor y este no tendrá la obligación de
            prestar el servicio ni de reembolsar lo anticipado.
            <br />
            <br />
            EL pago se facilitará por Zaturna utilizando el método de pago por
            medio de transacción electrónica a través de la plataforma ya
            mencionada; Luego de ello Zaturna le notificara el recibo por correo
            electrónico.
            <br />
            <br />
            Usted entiende que el uso de los Servicios puede derivar en cargos
            por los servicios o bienes que reciba de un proveedor (“Cargos”).
            Después de que haya recibido los servicios u obtenido los bienes
            mediante el uso de los Servicios, Zaturna facilitará su pago de los
            Cargos aplicables entre el proveedor y/o cliente. El pago de los
            Cargos de dicha manera se considerará como el pago efectuado
            directamente por usted al proveedor.
            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">Cancelaciones.</div>
            <br />

            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">Limitación de responsabilidad.</div>
            <br />
            Zaturna no será responsable de daños indirectos, incidentales,
            especiales, ejemplares, punitivos o emergentes, incluidos el lucro
            cesante, la pérdida de datos, la lesión personal o el daño a la
            propiedad, ni de perjuicios relativos, o en relación con, o de otro
            modo derivados de cualquier uso de los servicios, incluso aunque
            Zaturna haya sido advertido de la posibilidad de dichos daños.
            Zaturna no será responsable de cualquier daño, responsabilidad o
            pérdida que deriven de: (1) cualquier transacción o relación entre
            usted y cualquier proveedor, aunque Zaturna hubiera sido advertido
            de la posibilidad de dichos daños. Zaturna no será responsable del
            retraso o de la falta de ejecución resultante de causas que vayan
            más allá del control razonable de Zaturna. Usted podrá utilizar los
            servicios de Zaturna para obtener, arrendar, negociar, cotizar o
            planificar servicios de terceros proveedores y acepta que Zaturna no
            tiene responsabilidad alguna hacia usted en relación con cualquier
            servicio o bienes prestados a usted por terceros proveedores. El
            pago deberá estar anticipado con los días que acuerden el proveedor
            y el cliente, es por eso que ZATURNA no será responsable en los
            conceptos de devoluciones o de los acuerdos que hayan pactado las
            partes en la prestación de servicios.
            <br />
            <br />
            No existe entre usted, Zaturna o cualquier Tercer proveedor una
            empresa conjunta o relación de socios, empleo o agencia como
            resultado del contrato entre usted y Zaturna o del uso de los
            Servicios. Si cualquier disposición de estas Condiciones se
            considerara ilegal, nula o inexigible, ya sea en su totalidad o en
            parte, de conformidad con cualquier legislación, dicha disposición o
            parte de esta se considerará que no forma parte de estas
            Condiciones, aunque la legalidad, validez y exigibilidad del resto
            de las disposiciones de estas Condiciones no se verá afectada. En
            ese caso, las partes deberán reemplazar dicha disposición ilegal,
            nula o inexigible, en todo o en parte por una disposición legal,
            válida y exigible que tenga, en la medida de lo posible, un efecto
            similar al que tenía la disposición ilegal, nula o inexigible, dados
            los contenidos y el propósito de estas Condiciones.

            <br />
            <br />
          </div>

          <div>
            <div class="font-weight-bold">Acceso a la red y dispositivos.</div>
            <br />
            Usted es responsable de obtener el acceso a la red de datos
            necesario para utilizar los Servicios. Podrán aplicarse las tarifas
            y tasas de datos y mensajes de su red móvil si usted accede o
            utiliza los Servicios desde un dispositivo inalámbrico y usted será
            responsable de dichas tarifas y tasas. Usted es responsable de
            adquirir y actualizar el hardware compatible o los dispositivos
            necesarios para acceder y utilizar los Servicios y Aplicaciones y
            cualquier actualización de estos. Zaturna no garantiza que los
            Servicios, o cualquier parte de estos, funcionen en cualquier
            hardware o dispositivo particular. Además, los Servicios podrán ser
            objeto de disfunciones o retrasos inherentes al uso de Internet y de
            las comunicaciones electrónicas.

            <br />
            <br />
          </div>
        </div>
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/LandingPage/Shared/Navbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Footer from "@/components/LandingPage/Shared/Footer.vue";

export default {
  name: "TermsOfServicePublic",
  components: {
    Navbar,
    SearchBar,
    Footer,
  },
  data: () => ({}),
};
</script>

<style scoped lang="scss">
.background-style {
  min-height: 40vh;
  background: url("../../assets/img/Image_Politicas.png") no-repeat center
    center !important;
  background-size: cover !important;
}
</style>
